import { Input } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const DosageComp = ({ dosage, onDosageChange, setMedicineTime }) => {
  const [dosageOptions, setDosageOptions] = useState([]);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const componentRef = useRef(null);

  const fetchDosages = async () => {
    try {
      const response = await axios.get(
        "https://medapi.medisoftindia.com/api/dosages"
      );
      const { data } = response;
      const options = data.data.map((el) => {
        return {
          label: `${el.DosagePattern} - (${el.MedicineTimeName})`,
          value: el.ID,
        };
      });
      setDosageOptions(options);
    } catch (error) {
      console.error("Error fetching dosage options:", error.message);
    }
  };

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchDosages();
  }, []);

  const renderOptions = dosageOptions.map((option) => (
    <li
      onClick={() => {
        onDosageChange(option.label.split(" ")[0]);
        setMedicineTime(option.label.match(/\((.*?)\)/)[1]);
      }}
      key={option.value}
      className="p-2 rounded-md bg-gray-100 cursor-pointer w-full hover:bg-gray-200 transition-all duration-300"
    >
      {option.label}
    </li>
  ));

  return (
    <div
      style={{
        position: "relative",
        width: "150px", // Reduced width for Dosage textbox
        maxWidth: "350px", // Set a maximum width
      }}
      ref={componentRef}
    >
      <Input
        value={dosage}
        onChange={(e) => {
          const inputValue = e.target.value;
          onDosageChange(inputValue); // Allow manual input
        }}
        onFocus={() => setOptionsVisible(true)}
        style={{ width: "100%" }} // Make input take the full width of the parent container
      />

      {optionsVisible && (
        <ul
          className="flex flex-col justify-start items-start gap-2 bg-white shadow-md rounded-md w-auto h-auto p-2"
          style={{
            listStyle: "none",
            position: "absolute",
            top: "35px",
            left: 0,
            zIndex: 10,
            minWidth: "150px", // Minimum width for the dropdown
            maxWidth: "300px", // Maximum width for the dropdown
          }}
        >
          {renderOptions}
        </ul>
      )}
    </div>
  );
};

export default DosageComp;
