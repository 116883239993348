import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AutoComplete, Input, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import DosageComp from './DosageComp';

const { TextArea } = Input;

const MedicineRow = ({ rowData, index, handleInputChange, handleDeleteRow }) => {
  const [medicineSuggestions, setMedicineSuggestions] = useState([]);
  const [medicineTimeSuggestions, setMedicineTimeSuggestions] = useState([]);
  const [medicineTime, setMedicineTime] = useState('');
  const [composition, setComposition] = useState(''); // State for composition

  // Fetch medicine suggestions based on input
  const fetchMedicines = async (name) => {
    if (name.length > 1) {
      try {
        const response = await axios.get(
          `https://medapi.medisoftindia.com/api/medicine-search?MedicineName=${name}`
        );
        if (Array.isArray(response.data)) {
          setMedicineSuggestions(
            response.data.slice(0, 7).map((medicine) => ({
              value: medicine.MedicineName,
              label: (
                <div>
                  <strong>{medicine.MedicineName}</strong>
                  <br />
                  <span style={{ fontSize: '12px', color: '#888' }}>
                    {medicine.FullComposition}
                  </span>
                </div>
              ),
              composition: medicine.FullComposition, // Store composition
              DosagePattern: medicine.DosagePattern,
              FrequencyName: medicine.FrequencyName,
              MedicineTime: medicine.MedicineTime,
              NumberOfDays: medicine.NumberOfDays, // Set NumberOfDays
            }))
          );
        } else {
          console.error('Unexpected data format', response.data);
        }
      } catch (error) {
        console.error('Error fetching medicine data:', error);
      }
    } else {
      setMedicineSuggestions([]);
    }
  };

  // Fetch medicine time suggestions
  const fetchMedicineTimes = async () => {
    try {
      const response = await axios.get('https://medapi.medisoftindia.com/api/medicine-times');

      if (response.data && Array.isArray(response.data.data)) {
        setMedicineTimeSuggestions(
          response.data.data.map((time) => ({
            value: time.Name,
          }))
        );
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching medicine times:', error);
    }
  };

  useEffect(() => {
    fetchMedicineTimes();
  }, []);

  // Handle selection of medicine and auto-fill Dosage, Frequency, Medicine Time, and NumberOfDays
  const handleMedicineSelect = (value, option) => {
    handleInputChange(index, { target: { name: 'medicineName', value } });

    const dosagePart = option.DosagePattern || ''; 
    const frequencyPart = option.FrequencyName || '';
    const medicineTimePart = option.MedicineTime || ''; 
    const numberOfDaysPart = option.NumberOfDays || '';

    // Set composition and update other fields
    setComposition(option.composition);
    handleInputChange(index, { target: { name: 'dosage', value: dosagePart } });
    handleInputChange(index, { target: { name: 'frequency', value: frequencyPart } });
    setMedicineTime(medicineTimePart);
    handleInputChange(index, { target: { name: 'days', value: numberOfDaysPart } }); // Set NumberOfDays
  };
  
  // Clear all input fields and reset the first row
  const handleClearAll = () => {
    handleInputChange(index, { target: { name: 'medicineName', value: '' } });
    handleInputChange(index, { target: { name: 'dosage', value: '' } });
    handleInputChange(index, { target: { name: 'time', value: '' } });
    handleInputChange(index, { target: { name: 'frequency', value: '' } });
    handleInputChange(index, { target: { name: 'days', value: '' } });
    handleInputChange(index, { target: { name: 'note', value: '' } });
    setComposition('');
    setMedicineTime('');
  };
  

  return (
    <div className="flex flex-wrap gap-4 items-start justify-between w-full" style={{ marginBottom: '16px' }}>
      <div style={{ flex: '1' }}>
        {/* Medicine AutoComplete */}
        <AutoComplete
          options={medicineSuggestions}
          onSearch={fetchMedicines}
          onSelect={(value, option) => handleMedicineSelect(value, option)}
          popupMatchSelectWidth={false}  // Suggestion width will be dynamic
        >
          <Input
            placeholder="Medicine Name"
            value={rowData.medicineName}
            onChange={(e) => handleInputChange(index, e)}
            style={{ width: '100%', fontSize: '12px' }} // Full width within its container
          />
        </AutoComplete>

        {/* Display Composition below Medicine Name */}
        {composition && (
          <div style={{
            fontSize: '12px',
            color: '#888',
            marginTop: '4px',
            whiteSpace: 'nowrap'
             // Prevent wrapping
          }}>
            {composition}
          </div>
        )}
      </div>

      {/* DosageComp Component */}
      <div style={{ flex: '1' }}>
        <DosageComp 
          dosage={rowData.dosage}
          onDosageChange={(value) => handleInputChange(index, { target: { name: 'dosage', value } })}
          setMedicineTime={setMedicineTime}
        />
      </div>

      {/* Medicine Time AutoComplete */}
      <div style={{ flex: '1' }}>
        <AutoComplete
          options={medicineTimeSuggestions}
          style={{ width: '100%' }}  // Full width within its container
          placeholder="Time"
          value={medicineTime}
          onSelect={(value) => {
            setMedicineTime(value);
            handleInputChange(index, { target: { name: 'time', value } });
          }}
        >
          <Input
            placeholder="Time"
            value={medicineTime}
            onChange={(e) => {
              const timeValue = e.target.value;
              setMedicineTime(timeValue); 
              handleInputChange(index, { target: { name: 'time', value: timeValue } });
            }}
            style={{
              width: '100%', // Full width within its container
              fontSize: '12px', // Reduced font size
              whiteSpace: 'normal', // Allow wrapping to multiple lines
              wordBreak: 'break-word', // Break words when necessary
            }}
          />
        </AutoComplete>
      </div>

      {/* Frequency Input */}
      <div style={{ flex: '0 0 100px' }}>
        <Input
          placeholder="Frequency"
          name="frequency"
          value={rowData.frequency}
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%', fontSize: '12px' }}
        />
      </div>

      {/* Days Input */}
      <div style={{ flex: '0 0 100px' }}>
        <Input
          placeholder="Days"
          name="days"
          value={rowData.days}  // Days will come from NumberOfDays in medicine selection
          onChange={(e) => handleInputChange(index, e)}
          style={{ width: '100%', fontSize: '12px' }}
        />
      </div>

      {/* Note TextArea - Multiline */}
      <div style={{ flex: '0 0 130px' }}>
        <TextArea
          placeholder="Note"
          name="note"
          value={rowData.note}
          onChange={(e) => handleInputChange(index, e)}
          autoSize={{ minRows: 2, maxRows: 4 }} // Multiline input
          style={{ width: '100%' }}
        />
      </div>

      {/* Delete Button or Placeholder */}
      <div style={{ flex: '0 0 40px', display: 'flex', gap: '4px', alignItems: 'center' }}>
        {index > 0 ? (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteRow(index)}
            style={{ width: '100%' }}
          />
        ) : (
          // Placeholder for the delete button in the first row to maintain space
          <div style={{ width: '100%' }}></div>
        )}

        {/* Clear All Button - Added to the first row */}
        {/* {index === 0 && (
          <Button type="primary" onClick={handleClearAll} style={{ marginLeft: '8px' }}>
            Clear All
          </Button>
        )} */}
      </div>
    </div>
  );
};

export default MedicineRow;
