// src/App.js
import React, { useState } from 'react';
import MedicineRow from './components/MedicineRow';
import './styles.css';

const App = () => {
  const [rows, setRows] = useState([
    { medicineName: '', dosage: '', time: '', frequency: '', days: '', note: '' },
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);

    // Automatically add a new row if user starts typing in the last row
    if (index === rows.length - 1) {
      setRows([
        ...rows,
        { medicineName: '', dosage: '', time: '', frequency: '', days: '', note: '' },
      ]);
    }
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  return (
    <div className="container">
      <h1 className="text-3xl font-bold text-center text-gray-800 mt-8 mb-6">Patient Prescription</h1>
      {rows.map((rowData, index) => (
        <MedicineRow
          key={index}
          index={index}
          rowData={rowData}
          handleInputChange={handleInputChange}
          handleDeleteRow={handleDeleteRow}
        />
      ))}
    </div>
  );
};

export default App;
